// CheckoutForm.js

import React, { useState } from 'react';
import { CardElement, useStripe, useElements, CardCvcElement } from '@stripe/react-stripe-js';

const CheckoutForm = ({ clientSecret }) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Test Kullanıcı'
        },
      }
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        setError(null);
        alert('Ödeme Başarılı!');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement className='py-3 px-2 w-full border'/>
      <button type="submit" disabled={!stripe}>
        Ödemeyi Tamamla
      </button>
      {error && <div>{error}</div>}
    </form>
  );
};

export default CheckoutForm;
