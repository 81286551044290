import React, { Component } from "react";
import { ReactComponent as FavIcon } from '../assets/pic/heart.svg';
import { ReactComponent as NewIcon } from '../assets/pic/new.svg';
import { ReactComponent as DiscountIcon } from '../assets/pic/discount.svg';
import { ReactComponent as TickIcon } from '../assets/pic/tick.svg';
import { ReactComponent as DiscoverIcon } from '../assets/pic/discover.svg';
import discountImage from '../assets/pic/discount.png';
import newImage from '../assets/pic/new.png';
import tickImage from '../assets/pic/tick.png';
import { Text} from '../languages/Language';
import { IMAGEURL } from "../constants";

class CategoryList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
      test: null,
      categories: [],
      buttonActive: null,
      itemitem: null
    }
  }


  formatString = (link) => {

    let temp = link;
    temp = temp.replace(/\s+/g, '');
    return temp
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productWebCategory !== this.props.productWebCategory && this.props.productWebCategory === "Home") {
      this.setState({ test: null });
    }
  }

  handleOpen = (i, z) => {
   var {toggleMenu} = this.props;
   if(i!=null){
    this.setState({itemitem:i})
   }

   toggleMenu()
    if (sessionStorage.getItem("items")) {
      this.setState({ buttonActive: this.props.WebCategory(i, z) });
      if (i) {
        if (this.state.test === i) {
          this.setState({ test: null });
        } else
          this.setState({ test: i });
      }

    }
  };

  render() {
    var {isCategoryOpen} = this.props;
    return (
      <>
        <div
          className={isCategoryOpen?
            "w-[30rem] overflow-y-hidden  md:flex flex-col text-company-primary-color":
            "lg:w-[30rem] md:w-[14rem] overflow-y-hidden  hidden md:flex flex-col text-company-primary-color"}
        >
          <h2
            className="text-md font-medium my-2  "
          >
            <Text tid="Categories" />
          </h2>

          <div
                className="flex flex-row w-full justify-start border-t-4 border-r-2 border-company-primary-color-100"
              >
                <button

                  onClick={() => this.handleOpen("PickedProducts", "Deals")}
                  className={(this.props.existCampaigns > 0
                        ? " flex flex-row p-1"
                        : " hidden")}
                >
                  <div
                    className="flex  w-10 justify-center"
                  >
                    <div>
                      <DiscountIcon
                        className=" w-8 h-8 bg-white border border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>
                  <div
                    className={"inline-flex w-full px-5 py-2 text-sm font-medium text-company-primary-color hover:bg-company-primary-color-100"
                      + (this.state.buttonActive === "Deals"
                        ? " bg-company-primary-color-100"
                        : " ")}
                  >
                    <p
                      className='text-left'
                    >
                      <Text tid="Sale" />
                    </p>
                  </div>
                </button>
                <button
                  onClick={() => this.handleOpen("PickedProducts", "New Products")}
                  className={(this.props.existNewItems > 0
                    ? " flex flex-row p-1 "
                    : " hidden")}
                >
                  <div title={<Text tid="Ok" />}
                    className="flex w-10 justify-center"
                  >
                    <div>
                      <NewIcon
                        className=" w-8 h-8 bg-white border border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>

                </button>

                <button
                  onClick={() => this.handleOpen("PickedProducts", "Favorites")}
                  className={(this.props.existFavorites > 0
                    ? " flex flex-row p-1"
                    : " hidden")}
                >
                  <div className="flex  w-10  justify-center">
                    <div>
                      <FavIcon
                        className="text-company-secondary-color fill-company-secondary-color w-8 h-8 bg-white border
                      border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>

                </button>

                <button
                  onClick={() => this.handleOpen("PickedProducts", "Your Choice")}
                  className={(this.props.existSoldItems > 0
                    ? " flex flex-row p-1 "
                    : " hidden")}
                >
                  <div className="flex w-10 justify-center">
                    <div>
                      <TickIcon
                        className="p-0.5 text-company-primary-color fill-company-primary-color w-8
                      h-8 bg-white border border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>

                </button>

                <button
                  onClick={() => this.handleOpen("PickedProducts", "Discover")}
                  className={(this.props.existNotSoldItems > 0
                    ? " flex flex-row p-1"
                    : " hidden")}
                >
                  <div className="flex w-10 justify-center">
                    <div>
                      <DiscoverIcon
                        className="p-0.5 text-company-primary-color fill-company-primary-color w-8
                      h-8 bg-white border border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>

                </button>
              </div>

          <div
            className="flex flex-col w-full border-b-4 border-r-2 text-xxs overflow-y-auto border-company-primary-color-100"
          >
            <div
              className="p-1"
            >


              {
              this.props.itemCategoryName
                ? this.props.itemCategoryName.map((item, index) => {
                  // item kategorisindeki öğelerin sayısını hesaplama
                  const itemCount = this.props.allCategories
                  .filter(subCategory => subCategory.itemCategoryName === item)
                  .reduce((sum, subCategory) => sum + subCategory.itemCount, 0)??0;
                  return (
                    <div key={index}>
                      <button
                        onClick={() => this.handleOpen(item, null)}
                        className="flex flex-row p-0 py-1 w-full"
                      >
                        <div className="flex flex-row w-10 justify-center p-1">
                          <div className="flex flex-row w-10 h-8 rounded-lg bg-transparent">
                            <img
                              src={this.formatString(IMAGEURL + "/category/" + item + ".jpg")}
                              onError={(e) => {
                                e.currentTarget.onerror = null; // prevents looping
                                e.currentTarget.src = "noImage.jpg";
                              }}
                              className="w-full h-full object-fill rounded-lg shadow-lg shadow-slate-950"
                              alt=''
                            />
                          </div>
                        </div>
                        <div
                          className={"w-full inline-flex justify-between px-4 h-10 items-center text-sm font-medium text-company-primary-color focus:bg-company-primary-color-100 hover:bg-company-primary-color-100"
                            + (this.state.test === item
                              ? " bg-company-primary-color-100"
                              : " ")}
                        >
                          <p className='text-left'>
                            {item} ({itemCount})
                          </p>
                        </div>
                      </button>


                    </div>
                  );
                }) : null
            }

            </div>
          </div>


            <div className="flex flex-col h-64 border-b-4 border-r-2 text-xxs border-company-primary-color-100">
                <div className="h-12">
                  <h2 className="text-md font-medium my-2  " >
                  <Text tid="Brands" />
                  </h2>
                </div>
            <div className="h-48 overflow-y-auto">
            {
            this.state.itemitem !== "" &&
            this.props.allCategories
            ? this.props.allCategories.map((subCategory, index) => (
              subCategory.itemCategoryName === this.state.itemitem
                ?
                (
                  <button
                    onClick={() => this.handleOpen(null, subCategory.itemSubCategoryName)}
                    key={index}
                    className={"w-full flex flex-row justify-start py-1 items-center text-xs font-medium text-company-primary-color hover:bg-gray-50"
                      + (this.state.test === subCategory.itemCategoryName && subCategory.itemSubCategoryName !== ""
                        ? " flex"
                        : " hidden")}
                  >
                    <div className="flex flex-row w-10 justify-center p-1">
              <div className="flex flex-row w-10 h-8 rounded-sm bg-transparent">
                <img
                  src={this.formatString(IMAGEURL + "/subcategory/" + subCategory.itemSubCategoryName.replace(" ","") + ".jpg")}
                  onError={(e) => {
                    e.currentTarget.onerror = null; // prevents looping
                    e.currentTarget.src = "noImage.jpg";
                  }}
                  className="w-full h-full object-fill rounded-sm shadow-lg shadow-slate-950"
                  alt=''
                />
              </div>
            </div>
                    <div className="w-full inline-flex justify-between px-4 h-10 items-center text-sm font-medium text-company-primary-color focus:bg-company-primary-color-100 hover:bg-company-primary-color-100">
                    <ul>
                      <li>
                        <p className='text-left'>
                          {subCategory.itemSubCategoryName} ({subCategory.itemCount})
                        </p>
                      </li>
                    </ul>
                    </div>
                  </button>
                ) : null
            ))
            : null
          }
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CategoryList;