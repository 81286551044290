
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { roundDigits } from '../utils';
import { CURRENCY } from '../constants';


const StripePage = ({miktar}) => {
  const stripePromise = loadStripe('pk_test_51PH13VRsZipBz0hYhh485de11vlC4U0EMjE3ro5DVXNj6DMddPp5Ac7q4ftFMPfX9ApAWl9dg50nBmuxzIiuxfmc00pzwk346c');

  const [paymentIntent, setPaymentIntent] = useState(stripePromise);

  const createPaymentIntent = async (amount) => {
    const response = await fetch('/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount }),
    });

    const data = await response.json();
    setPaymentIntent(data.client_secret);
  };
  const handleCreatePaymentIntent = () => {
    const amount = miktar * 100;
    createPaymentIntent(parseInt(amount));
  };

  return (
    <div>
      <h1>{roundDigits(miktar)}  {CURRENCY} </h1>
      <br></br>
      <button onClick={handleCreatePaymentIntent}>Ödeme İşlemi Oluştur</button>
      <br></br><br></br>
      {paymentIntent && (
        <Elements stripe={stripePromise}>
          <CheckoutForm clientSecret={paymentIntent} />
        </Elements>
      )}
    </div>
  );
};

export default StripePage;
